/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* !only work around found for iphones */
@media screen and (max-width: 768px) {
  /* CSS specific to iOS devices */
  @supports (-webkit-touch-callout: none) {
    .cs-chat-container {
      align-self: flex-end;
      width: 100%;
      height: 100%;
      max-width: 95%;
      max-height: 94%;
    }
  }
}

.cs-button,
.cs-button--send {
  color: #1b98f5;
}

.cs-button,
.cs-button--send:hover {
  background: transparent;
  color: #1b98f5;
}

.cs-message--outgoing .cs-message__content {
  background-color: #eee;
}

@media screen and (min-width: 768px) {
  .cs-main-container {
    border-radius: 12px;
  }
}
